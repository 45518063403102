'use strict';

(function() {

class AdminController {
  constructor($scope, User, Site, Organization, Auth, Modal, toastr, $uibModal) {
    this.$scope = $scope;
    this.Modal = Modal;
    this.isAdmin = Auth.isAdmin;
    this.Site = Site;
    this.User = User;
    this.Organization = Organization;
    this.toastr = toastr;
    this.$uibModal = $uibModal;
    this.users = User.query();
    this.organizations = [];
    this.host = location.host;
    this.protocol = location.protocol;

    if(this.isAdmin()) {
      this.Organization.get().$promise
      .then(response=> {
        if (response.data && response.data.length) {
          this.organizations = response.data;
          _.each(this.organizations, organization => {
            organization.sites = [];
            this.Site.get({ id: 'for-org', controller: organization._id}).$promise
              .then(response=> {
                if (response.data && response.data.length) {
                  organization.sites = response.data;
                }
                organization.admins = [];
                this.User.get({ id: 'org-admins', controller: organization._id}).$promise
                .then(response=> {
                  if (response.data && response.data.length) {
                    organization.admins = response.data;
                  }
                });
              });
          });
        }
      })
      .catch(err=> {
        console.log(err);
        // this.loading = false;
        // this.stopSpin('spinner-1');
      });
    }

    this.$scope.$on('userAdded', (event, user) => {
      this.users.unshift(user);
    });
  }

  delete(user) {
    var self = this;
    this.Modal.confirm.delete(function(user) {
      user.$remove(function(deleted) {
        self.users.splice(self.users.indexOf(user), 1);
        self.toastr.success('User deleted successfully');
      }, function(error) {
        console.log(error);
        self.toastr.error('Something went wrong. Please contact administrator.');
      });
    })(user.email, user);
  }

  edit(user) {
    var self = this;
    var modalInstance = this.$uibModal.open({
      size: 'lg',
      templateUrl: 'components/modal/user/addEditUserModal/addEditUserModal.html',
      controller: 'AddEditUserModalCtrl',
      controllerAs: 'aEUser',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        options: {
          title: 'Edit User',
          user: user
        }
      }
    });
    modalInstance.result.then(function(user) {
      var targetUser = _.find(self.users, { _id: user.data._id });
      if(targetUser) {
        targetUser = _.merge(targetUser, user.data);
      } else {
        this.users = User.query();
      }
    });
  }

  openAddOrganizationModal() {
    var self = this;
    var modalInstance = this.$uibModal.open({
      size: 'md',
      templateUrl: 'components/modal/organization/addEditOrganizationModal/addEditOrganizationModal.html',
      controller: 'AddEditOrganizationModalCtrl',
      controllerAs: 'aEOrganization',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        options: {
          title: 'Add New Client'
        }
      }
    });
    modalInstance.result.then(function(organization) {
      self.organizations.unshift(new self.Organization(organization.data));
    });
  }

  deleteOrg(organization) {
    var self = this;
    this.Modal.confirm.delete(function(organization) {
      organization.$remove(function(deleted) {
        self.organizations.splice(self.organizations.indexOf(organization), 1);
        self.toastr.success('Client deleted successfully');
      }, function(error) {
        console.log(error);
        self.toastr.error('Something went wrong. Please contact administrator.');
      });
    })(organization.name, organization);
  }

  editOrg(organization) {
    var self = this;
    var modalInstance = this.$uibModal.open({
      size: 'md',
      templateUrl: 'components/modal/organization/addEditOrganizationModal/addEditOrganizationModal.html',
      controller: 'AddEditOrganizationModalCtrl',
      controllerAs: 'aEOrganization',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        options: {
          title: 'Edit Client',
          organization: organization
        }
      }
    });
    modalInstance.result.then(function(organization) {
      var targetOrganization = _.find(self.organizations, { _id: organization.data._id });
      if(targetOrganization) {
        targetOrganization = _.merge(targetOrganization, organization.data);
      } else {
        self.Organization.get().$promise
          .then(response=> {
            if (response.data && response.data.length) {
              self.organizations = response.data;
            }
          });
      }
    });
  }
}

angular.module('windmanagerApp.admin')
  .controller('AdminController', AdminController);

})();
